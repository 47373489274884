<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">Account</div>
      <h2 class="text-h3 text-lg-h2 mb-6">Регистрация</h2>
    </v-responsive>

    <v-card class="pa-4 mx-auto" max-width="600">
      <v-card-text>
        <v-form v-model="formValidity" @submit.prevent="submit">
          <div class="d-flex flex-column flex-md-row">
            <v-text-field
              v-model="form.firstName"
              autocomplete="name"
              label="Имя"
              outlined
              class="mr-md-2"
              :rules="rules.firstNameRules"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.lastName"
              autocomplete="family-name"
              label="Фамилия"
              outlined
              :rules="rules.lastNameRules"
              required
            ></v-text-field>
          </div>
          <v-text-field
            v-model="form.email"
            autocomplete="username email"
            label="Email"
            outlined
            :rules="rules.emailRules"
            required
          ></v-text-field>
          <v-text-field
            v-model="form.password"
            autocomplete="new-password"
            label="Пароль"
            outlined
            :rules="rules.passwordRules"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            required
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-btn
            block
            class="secondary"
            x-large
            type="submit"
            :disabled="!formValidity"
          >Зарегистрироваться
          </v-btn>
        </v-form>

        <div class="d-flex my-3">
          <v-divider class="my-1"></v-divider>
          <div class="text-overline mx-1">Или регистрация через</div>
          <v-divider class="my-1"></v-divider>
        </div>

        <div class="d-flex justify-space-between">
          <v-btn outlined large class="flex-grow-1" color="secondary lighten-1">
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn outlined large class="flex-grow-1 mx-2" color="secondary lighten-1">
            <v-icon>mdi-twitter</v-icon>
          </v-btn>
          <v-btn outlined large class="flex-grow-1" color="secondary lighten-1">
            <v-icon>mdi-google</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <!--    <div class="text-overline text-uppercase mt-3 text-center">-->
    <!--      By signing up, you agree to the-->
    <!--      <router-link :to="{ name: 'legal.terms-of-service'}">Terms of Service</router-link> & <router-link :to="{ name:'legal.privacy-policy' }">Privacy Policy</router-link>-->
    <!--    </div>-->
    <div class="text-overline text-uppercase mt-3 text-center">
      Входя в систему, вы соглашаетесь с<br/>
      <router-link :to="{ name: 'legal.terms-of-service'}">Условиями использования</router-link>
      &
      <router-link :to="{ name:'legal.privacy-policy' }">Политикой конфиденциальности</router-link>
      сервиса
    </div>
  </v-container>
</template>

<script>
import AuthService from '@/service/AuthService'
import RegisterForm from '@/model/auth/RegisterForm'
import { ApolloError } from 'apollo-client'
import { GraphQLError } from 'graphql'
import ToastService from '@/service/ToastService'
import { ruleEmail, ruleRequired } from '@/utils/FormRules'

export default {
  name: 'SignupPage',
  data: () => ({
    showPassword: false,
    formValidity: false,
    form: {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    },
    rules: {
      firstNameRules: [ruleRequired()],
      lastNameRules: [ruleRequired()],
      emailRules: [ruleRequired(), ruleEmail()],
      passwordRules: [ruleRequired()]
    }
  }),
  created() {
    this.$store.dispatch('auth/logout')
  },
  methods: {
    submit() {
      const registerForm = RegisterForm.create(this.form)

      AuthService.register(registerForm)
        .then(() => {
          this.$router.push({ name: 'auth.signup-complete' })
        })
        .catch((error) => {
          const messages = error.graphQLErrors.map((graphQLError) => graphQLError.message)

          if (messages.includes('USER_ALREADY_EXISTS')) {
            ToastService.warning('Пользователь с таким Email уже зарегистрирован')
          } else {
            ToastService.error('Что-то пошло не так. Ошибка регистрации.')
          }
        })
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Sign up'
      }
    }
  }
}
</script>

<style scoped>

</style>
